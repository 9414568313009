import React, { Component } from 'react'
import RouteManager from '../../managers/RouteManager';
import { connect, ConnectedProps } from 'react-redux';
import { fetchReportsByPage } from '../../store/slices/reportSlice'
import ReportHolder from './ReportHolder';
import { debounce, isEmpty } from '../../utilities/Common';
import { AI_SUPPORTED_APPS, APP_NAME, APPS, appsForPeople } from '../../constants/Constant';
import { ReportManager } from '../../managers/ReportManager';


class PeopleReportList extends Component<PropsFromRedux, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            loader: true,
            showListPicker: false,
            showFavourite: false,
            searchText: '',
            appName:''
        }

    }
    componentDidMount() {
        RouteManager.setPresenter(this);
        ReportManager.geInstance().setSelectedReport(null);
        this.getAllReports();
    }
    getAllReports = (appName = '') => {
        this.setState({ loader: true });
        let dataToSend:any = {};
        if (!isEmpty(appName) || !isEmpty(this.state.appName)) {
            dataToSend.appName = !isEmpty(appName) ? appName : this.state.appName;
        }
        if (!isEmpty(this.state.searchText)) {
            dataToSend.search = this.state.searchText
        }
        if (!isEmpty(this.state.selectedUser)) {
            dataToSend.query = `userId=${this.state.selectedUser?.userId}`
        }
        this.props.fetchReportsByPage(dataToSend).then((response: any) => {
            this.setState({ loader: false });
        })
    }
    onSearch = (searchText:any) => {
        this.setState({ searchText }, this.callDebounce)
    }
    callDebounce = debounce(() => this.getAllReports(), 300);

    render() {
        const apps = AI_SUPPORTED_APPS.map(app => app.code);
        return (
            <ReportHolder
                headerName="People AI Report"
                reports={this.props.reports?.content}
                onDelete={() => this.getAllReports()}
                onUpdate={() => this.getAllReports()}
                onAppSelection={(index: number) => { this.setState({ appName: index === 0 ? '' : apps[index] }, () => this.getAllReports(index === 0 ? '' : apps[index])) }}
                onOwnerSelection={(user:any)=> this.setState({selectedUser:user},()=>this.getAllReports())}
                onSearch={(searchText: any) => this.onSearch(searchText)}
                aiReport={true}
            />
        )
    }

}
const mapStateToProps = (state: any) => ({
    reports: state.reports.reports
});
const mapDispatchToProps = { fetchReportsByPage };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PeopleReportList);
