import React, { useState, useEffect, Fragment } from 'react';

import DKSidebar from './DKSidebar/DKSidebar';
import AppManager from '../../managers/AppManager';
import { showToast, TOAST_TYPE, DKIcons } from 'deskera-ui-library';

import Auth from '../../services/user/Auth';
import SideBarService from '../../services/common/Sidebar';
import {
  isMobileAppWebView,
  isViewportMobile
} from '../../utilities/ViewportSize';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getMultiTenantAccess,
  getTenantInfo,
  getTenants
} from '../../store/slices/tenantSlice';
import { PAGE_ROUTES } from '../../constants/Constant';
import RouteManager from '../../managers/RouteManager';

export default function LeftMenu() {
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const tenantInfo = useAppSelector(getTenantInfo());
  const tenantList = useAppSelector(getTenants());
  const hasMultiTenantAccess = useAppSelector(getMultiTenantAccess());

  useEffect(() => {
    window.addEventListener('onSideBarChange', handleSidebarToggle);
    window.addEventListener('resize', handleSidebarOnResize);

    return () => {
      window.removeEventListener('onSideBarChange', handleSidebarToggle);
      window.removeEventListener('resize', handleSidebarOnResize);
    };
  }, []);

  const handleSidebarToggle = () => {
    setIsMenuExpanded(SideBarService.isSideBarExpanded);
  };

  const handleSidebarOnResize = () => {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  };

  const onSelectTenant = (newSelectedTenantDetails: {
    tenantId: number;
    tenantName: string;
  }) => {
    if (newSelectedTenantDetails.tenantId !== tenantInfo?.tenantId) {
      Auth.getInstance()
        .switchTenant({ tenantId: newSelectedTenantDetails.tenantId })
        .then(
          (res) => AppManager.reloadApp(),
          (err) => {}
        );
    } else {
      showToast('Current organization', TOAST_TYPE.SUCCESS);
    }
  };
  const getMenuItems = () => {
    let leftMenuNavigation = [
      {
        name: 'My Reports',
        icon: DKIcons.white.ic_home,
        route: PAGE_ROUTES.REPORTS,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: 'Recent',
        icon: DKIcons.white.ic_recent,
        route: PAGE_ROUTES.RECENT,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: 'Starred',
        icon: DKIcons.white.ic_key_2,
        route: PAGE_ROUTES.STARRED,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: 'Templates',
        icon: DKIcons.white.ic_dashboard,
        route: PAGE_ROUTES.TEMPLATES,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: 'Settings',
        icon: DKIcons.white.ic_settings,
        route: PAGE_ROUTES.SETTINGS,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      }
    ];
    if (hasMultiTenantAccess && hasMultiTenantAccess.orgs && hasMultiTenantAccess.orgs.length>1) {// ai plan check 
      leftMenuNavigation.push({
        name: 'People Reports',
        icon: DKIcons.white.ic_user,
        route: PAGE_ROUTES.PEOPLE_AI_REPORT,
        onClick: onMenuItemClick,
        visible: !isMobileAppWebView()
      });
    }
    return leftMenuNavigation;
  };
  const onMenuItemClick = (route: any) => {
    if (isViewportMobile()) {
      SideBarService.toggleSideBar();
      handleSidebarToggle();
    }

    RouteManager.navigateToPage(route);
  };
  return (
    <Fragment>
      <div
        className={`transparent-background parent-height parent-width position-absolute ${
          isMenuExpanded
            ? 'display-only-mobile z-index-5'
            : 'display-none z-index-0'
        }`}
        onClick={() => SideBarService.toggleSideBar()}
      ></div>
      <DKSidebar
        className="z-index-6"
        allowTenantSelection={!isMobileAppWebView()}
        collapsedWidth={0}
        expandedWidth={230}
        menuItemList={getMenuItems()}
        tenantList={tenantList}
        isMenuExpanded={isMenuExpanded}
        selectedTenantDetails={{
          tenantId: tenantInfo?.id,
          tenantName: tenantInfo?.name
        }}
        onAddNewTenantTapped={() => {
          // setShowAddTenantPopup(true)
        }}
        onTenantSelect={onSelectTenant}
        onExpandCollapse={() => SideBarService.toggleSideBar()}
      />
    </Fragment>
  );
}
