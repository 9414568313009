import React, { Component } from 'react'
import { DKLabel, DKIcon, DKIcons, DKButton, showAlert, DKSpinner, DKListPicker, DKSearchBar } from 'deskera-ui-library';
import { AI_SUPPORTED_APPS, APPS, APP_NAME, PAGE_ROUTES, appsForPeople } from '../../constants/Constant';
import RouteManager from '../../managers/RouteManager';
import ic_report_thumb_1 from "../../assets/customReport/ic_report_thumb_1.png";
import ic_report_thumb_2 from "../../assets/customReport/ic_report_thumb_2.png";
import ic_report_thumb_3 from "../../assets/customReport/ic_report_thumb_3.png";
import ic_report_thumb_4 from "../../assets/customReport/ic_report_thumb_4.png";
import ic_report_thumb_5 from "../../assets/customReport/ic_report_thumb_5.png";
import ReportService from '../../services/reports/Report';
import ic_all from "../../assets/ic_all.png"
import ic_erp from '../../assets/ic_erp.png';
import ic_crm from '../../assets/ic_crm.png';
import ic_people from '../../assets/ic_people.png';
import star from '../../assets/star.png'
import favourite from '../../assets/favourite.png'
import NoRecordFound from '../common/NoRecordFound';
import { isEmptyObject } from '../../utilities/Common';
import '../../styles/Reports.css'
import OwnerFilterButton from '../common/OwnerFilterButton';
import SaveReport from '../../pages/SaveReport';


const _reportService = ReportService.getInstance();
export default class ReportHolder extends Component<any, any> {
    reportToCopy: any;

    constructor(props: any) {
        super(props)
        this.state = {
            selectedApp: APPS[0].name,
            showListPicker: false,
            showFavourite: false,
            searchText: '',
            loader: true,
            showSavePopup: false
        }

        this.reportToCopy = null;
    }
    componentDidMount() {
        RouteManager.setPresenter(this);
    }
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.reports !== this.props.reports) this.setState({ loader: false });
    }
    render() {
        const showLoader = this.state.loader;        
        const showEmptyState = isEmptyObject(this.getFilterReportForGlobalAccessOrNonGlobalAccess())
        return (
            <>
                {this.getHeader()}
                <div
                    className="column parent-width p-h-xxl pt-xs align-self-center"
                >
                    <div className="row flex-wrap" style={{ columnGap: '2%', rowGap: 20 }}>
                        {(showLoader && showEmptyState) ? (
                            <div className="d-flex parent-size align-items-center justify-content-center fw-b position-absolute" style={{ top: 0, left: 0 }} >
                                <DKSpinner
                                    iconClassName="ic-s-2"
                                    className="ml-m"
                                    title="loading reports..."
                                />
                            </div>
                        ) : (!showEmptyState ? this.getReports() : this.getEmptyState())}
                    </div>
                </div>
                {this.getSavePopup()}
            </>
        )
    }
    getFilterReportForGlobalAccessOrNonGlobalAccess() {
        const reportList = this.props.reports?.filter((report:any)=>{
            if(this.props.aiReport) {
                return report.peopleGlobalAccess 
            }  else {
                return !report.peopleGlobalAccess 
            }
        })
        return reportList
    }
    getEmptyState = () => (
        <div className="column parent-width">
            <NoRecordFound
                title={`${this.props.isStarredReport ? 'No starred Report Found' : 'No Report found' }`}
                subTitle={`${this.props.isStarredReport ? 'Your favourite reports will appear here, once star marked.' : 'Once available, will appear here. Click on button below to create an report.'}`}
                isButton={true}
                buttonTitle={`${this.props.isStarredReport ? 'My Reports' : '+ New Report'}`}
                onClick={() => {
                    if(this.props.aiReport) {
                        RouteManager.navigateToPage(this.props.isStarredReport ? PAGE_ROUTES.REPORTS : PAGE_ROUTES.CREATE_AI_REPORT)
                    } else {
                        RouteManager.navigateToPage(this.props.isStarredReport ? PAGE_ROUTES.REPORTS : PAGE_ROUTES.CREATE_REPORT)
                    }
                }}
            />
        </div>
    );
    handleOwnerSelection = (selectedUser: any) => {
        this.props?.onOwnerSelection(selectedUser);
        this.setState({
            selectedOwner: selectedUser
          });
    }
    getHeader() {
        return (
            <div className="row row-responsive mobile-flex-wrap mobile-flex-gap-s justify-content-between bg-white p-h-xxl p-v-l" style={{
                position: 'sticky',
                top: 0,
                zIndex: 2
            }}>
                <div className="row justify-content-start width-auto">
                    <DKLabel text={`${this.props?.headerName || 'My Reports'}`} className="fs-l fw-m" />
                    {this.state.loader && <DKSpinner className="ml-s" />}
                </div>
                <div className="row width-auto mobile-flex-wrap mobile-parent-width justify-content-end mobile-justify-content-start mobile-flex-gap-s">
                    <DKSearchBar
                        className="report-search-bar"
                        placeholder="Search by name..."
                        onSearch={(searchText: any) => { this.setState({ searchText }, () => this.props?.onSearch(searchText)) }}
                        searchText={this.state.searchText}
                    />
                    <div
                        className="row justify-content-between position-relative cursor-hand mr-r mobile-m-0 p-s border-m border-radius-m bg-gray1"
                        style={{ minWidth: '15%', width: 'auto' }}
                        onClick={() => this.showHideListPicker()}
                    >
                        <DKLabel
                            className="unselectable text-trailing text-align-left"
                            text={this.state.selectedApp || 'All'}
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        />
                        <DKIcon
                            src={DKIcons.ic_arrow_down2}
                            className="ic-xs unselectable ml-xs fw-m"
                        />
                        {this.state.showListPicker && this.getAppsListPicker()}
                    </div>
                    <OwnerFilterButton
                        selectedOwner={this.state.selectedOwner}
                        onChangeOwner={(selectedUser:any) => this.handleOwnerSelection(selectedUser)}
                    />
                    <DKButton
                        title="+ New Report"
                        className="text-white bg-button ml-r mobile-m-0"
                        onClick={() => {
                            if(this.props.aiReport) {
                                RouteManager.navigateToPage(PAGE_ROUTES.CREATE_AI_REPORT);
                            } else {
                                RouteManager.navigateToPage(PAGE_ROUTES.CREATE_REPORT);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
    showHideListPicker = () => {
        this.setState({ showListPicker: !this.state.showListPicker })
    }
    getAppsListPicker = () => {
        let apps:any =  [];
        let appIcons = [ic_all, ic_erp, ic_crm, ic_people]
        if(this.props.aiReport ) {
            apps = AI_SUPPORTED_APPS.map(app => app.name);
            appIcons = [ic_all, ic_people]
        } else {
            apps = APPS.map(app => app.name);
            appIcons = [ic_all, ic_erp, ic_crm, ic_people]
        }
        // APPS
        return (
          <DKListPicker
            data={apps}
            icons={appIcons}
            className="position-absolute border-m shadow-m z-index-3"
            style={{ top: 33, left: 0, width: 110 }}
            onSelect={(index: number) => {
              this.setState({ selectedApp: apps[index] }, () =>
                this.props?.onAppSelection(index)
              );
            }}
            onClose={() => this.showHideListPicker()}
          />
        );
    }

    getDeleteConfirmation = (report: any) => {
        const buttons = [
            {
                title: "Cancel",
                className: "bg-gray1 border-m",
            },
            {
                title: "Delete",
                className: "bg-red text-white ml-r",
                onClick: () => this.deleteReport(report.uid),
            },
        ];
        showAlert(
            "Delete Report?",
            "Deleting this report will delete it permanently you will not be able to restore it.",
            buttons
        );
    };
    deleteReport = (id: any) => {
        this.setState({ loader: true });
        _reportService.deleteReport(id).then((response: any) => {
            this.props?.onDelete();
            this.setState({ loader: false });
        })
    }
    getThumbnailRelativeUrlFromReport = (report: any) => {
        let thumbnailUrl;
        try {
            thumbnailUrl = new URL(report.thumbnail).pathname;
            thumbnailUrl = thumbnailUrl.substring(1);
        } catch (err) { }

        return thumbnailUrl;
    }
    updateReport = (report: any) => {
        let dataToSend = {
            ...report,
            thumbnail: this.getThumbnailRelativeUrlFromReport(report),
            favourite: !report.favourite
        }
        _reportService.updateReport(dataToSend, report.uid).then((response: any) => {
            this.props?.onUpdate();
        })
    }
    getReports() {
        let arr: any = [];
        const reportList = this.getFilterReportForGlobalAccessOrNonGlobalAccess()
        reportList?.forEach((report: any, i: number) => {
            arr.push(this.getReportCard(report, i));
        })
        return arr;
    }
    getReportCard(report: any, index: number) {
        let icons = [
            ic_report_thumb_1,
            ic_report_thumb_2,
            ic_report_thumb_3,
            ic_report_thumb_4,
            ic_report_thumb_5
        ];

        return (
            <div
                key={report.uid}
                className="mobile-parent-width border-radius-r bg-white column report-card cursor-hand"
                style={{ width: "23.5%", height: 300, overflow: "hidden" }}
                onClick={() => {
                    if(this.props.aiReport) {
                        const url = `${PAGE_ROUTES.CREATE_AI_REPORT}/${report.uid}`
                        RouteManager.navigateToPage(url);
                    } else {
                        RouteManager.navigateToPage(`${PAGE_ROUTES.CREATE_REPORT}/${report.uid}`);
                    }
                }}
            >
                <div className="parent-width position-relative column opacity-8">
                    <img
                        src={report.thumbnail || icons[1]}
                        alt=""
                        className="parent-width data-grid-bottom-border"
                        style={{ height: 150, objectFit: 'contain' }}
                    />
                    <div
                        className="parent-width parent-height bg-dark-gray opacity-1 position-absolute"
                    // style={{ opacity: 0.05 }}
                    />
                </div>
                <div className="column parent-width p-h-r pt-m">
                    <DKLabel text={report.reportName} className="fw-m fs-m text-ellipsis"
                        style={{
                            maxWidth: "100%",
                            WebkitLineClamp: 1
                        }}
                    />
                    <DKLabel
                        text={report.reportDesc || ''}
                        className="text-gray mt-xs text-ellipsis"
                        style={{
                            maxWidth: "100%",
                            // whiteSpace: "nowrap",
                            WebkitLineClamp: 1
                        }}
                    />
                    <DKLabel text={`Created:  ${report.createdDt}`} className="mt-xl text-gray" />
                    <div className="row justify-content-between mt-r">
                        <div className="row width-auto">
                            <DKIcon className={`${!report?.favourite ? 'opacity-5' : ''}`} style={{ height: 20, paddingLeft: 0, paddingRight: 8 }} src={report?.favourite ? favourite : star} onClick={(e: any) => { e.stopPropagation(); this.updateReport(report) }} />
                            <DKButton
                                icon={DKIcons.ic_copy}
                                className="opacity-5"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.showHideSavePopup(true, report);
                                }}
                            />
                            <DKButton icon={DKIcons.ic_delete} className="opacity-5" onClick={(e: any) => { e.stopPropagation(); this.getDeleteConfirmation(report) }} />
                        </div>
                        <DKButton
                            title="Open"
                            className="bg-button text-white"
                            onClick={() => {
                                if(this.props.aiReport) {
                                    RouteManager.navigateToPage(`${PAGE_ROUTES.CREATE_AI_REPORT}/${report.uid}`);
                                } else {
                                    RouteManager.navigateToPage(`${PAGE_ROUTES.CREATE_REPORT}/${report.uid}`);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
    /* **********Copy Report********** */
    getSavePopup = () => {
        return (this.state.showSavePopup && this.reportToCopy) ? (
            <SaveReport
                data={this.getDataToSave()}
                onSave={(data: any) => {
                    this.saveReportCopy(data);
                    this.showHideSavePopup(false);
                }}
                onCancel={() => this.showHideSavePopup(false)} />
        ) : null;
    }
    getDataToSave = () => {
        return (
            {
                appName: this.reportToCopy.appName || APP_NAME.ERP,
                data: this.reportToCopy.data,
                favourite: this.reportToCopy.favourite ?? false,
                reportDesc: this.reportToCopy.reportDesc || "",
                reportName: `Copy of ${this.reportToCopy.reportName || "Report"}`,
                thumbnail: this.getThumbnailRelativeUrlFromReport(this.reportToCopy),
                systemReport: this.reportToCopy.systemReport ?? false
            }
        )
    }
    saveReportCopy = (savePopupData: any) => {
        const payload = this.getDataToSave();
        payload.reportName = savePopupData.name || payload.reportName;
        payload.reportDesc = savePopupData.description || payload.reportDesc;
        payload.favourite = savePopupData.favourite ?? payload.favourite;

        _reportService
          .saveReport(payload)
          .then((res: any) => 
            RouteManager.navigateToPage(
              `${this.props.aiReport ? PAGE_ROUTES.CREATE_AI_REPORT : PAGE_ROUTES.CREATE_REPORT}/${res.uid}`
            )
          )
          .catch((err) => {
            console.error(err);
          });
    }
    showHideSavePopup = (shouldOpen: boolean, reportData?: any) => {
        if (shouldOpen) {
            this.reportToCopy = reportData;
        } else {
            this.reportToCopy = null;
        }

        this.setState({ showSavePopup: Boolean(shouldOpen) });
    }
}
