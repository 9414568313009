import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import TableService from '../../services/reports/Table';
import { IReportTable, KEY_VAL_OBJ } from '../../models/Common';
import Table from '../../services/table';
import DBTableManager from '../../managers/DBTableManager';
import { TABLES } from '../../constants/Constant';
import { useAppSelector } from '../hooks';
import { getTenants } from './tenantSlice';

export interface ReportState {
  reportsTables: KEY_VAL_OBJ<IReportTable>;
  loading: boolean;
  data: any;
}

const initialState: ReportState = {
  reportsTables: {},
  loading: false,
  data: {}
};

const _tableService = TableService.getInstance();
export const fetchTables = createAsyncThunk(
  'table/getTables',
  async (data, thunkAPI) => {
    const response = await Table.getAllViews();
    DBTableManager.populateViewMap(response);
    return response;
  }
);
export const fetchTableByName = createAsyncThunk(
  'table/getTableByName',
  async (data: any, thunkAPI) => {
    const tableId = DBTableManager.getTableId(data.tableName);
    const response = await Table.getTable(tableId);
    return response;
  }
);
export const fetchReportTablesByAppName = createAsyncThunk(
  'reports/fetchReportTablesByAppName',
  async (data: any) => {
    let response = await _tableService.fetchReportTablesByAppName(data);
    return response;
  }
);
function getFilteredTablesFromAction(action: any) {
  let data: any = {};
  action.payload.forEach((item: any) => {
    if (item.databaseName === 'crm') {
      data[item.name] = item;

      if (item.name === TABLES.CONTACT) {
        data[TABLES.SEGMENT] = item;
      }
    }
  });
  return data;
}
export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTables: (state, action: PayloadAction<any>) => {
      state.data = getFilteredTablesFromAction(action);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      state.data = getFilteredTablesFromAction(action);
    });
    builder.addCase(
      fetchReportTablesByAppName.fulfilled,
      (state, action: any) => {
        action.payload?.forEach((table: IReportTable) => {
          state.reportsTables[table.tableName] = table;
        });
        state.loading = false;
      }
    );
    builder.addCase(fetchReportTablesByAppName.pending, (state, action) => {
      state.loading = true;
    });
  }
});

const selfSelector = (state: RootState) => state.table;

export const getTableByName = (tableName: string) =>
  createSelector(
    selfSelector,
    (state: ReportState) => state.reportsTables[tableName]
  );

export const getTablesByAppName = (appName: string) =>
  createSelector(selfSelector, (state: ReportState) =>
    Object.values(state.reportsTables).filter(
      (table) => !appName || appName === table.appName
    )
  );

export default tableSlice.reducer;
