import { KEY_VAL_OBJ } from '../../models/Common';
import ApiConstants from '../../constants/ApiConstant';
import httpClient from '..';
import { APP_NAME } from '../../constants/Constant';
import { showAlert } from 'deskera-ui-library';
import { triggerDownload } from '../../utilities/Export';
import { IReportExportPayload } from '../../models/Report';
export const DEFAULT_PAGE_SIZE = 20;
class ReportService {
  private static _reportInstance: ReportService;

  public static getInstance(): ReportService {
    if (!ReportService._reportInstance) {
      ReportService._reportInstance = new ReportService();
    }
    return ReportService._reportInstance;
  }

  /* ************** Fetch Report Data API's ************** */
  public async fetchAllReports(data: any) {
    return httpClient.get(ApiConstants.REPORT.GET_ALL, { params: data });
  }

  public async fetchReportById(reportId: string) {
    return httpClient.get(ApiConstants.REPORT.GET_BY_ID(reportId));
  }

  public async executeReport(
    formData:any,
    params?: KEY_VAL_OBJ,
    appName = APP_NAME.CRM
  ) {
    // const payload: any = {
    //   chartType: 'TABLE',
    //   dataTables: [
    //     {
    //       appName: appName,
    //       customFieldColumns: formData.customFieldColumns || [],
    //       name: formData.tableName.toString(),
    //       columns: formData.columns
    //     }
    //   ],
    //   filters: formData.filters,
    //   customFieldFilters: [],
    //   sortBy: formData.sortBy?.column
    //     ? {
    //         column: formData.sortBy.column,
    //         table: formData.sortBy.table,
    //         direction:
    //           formData.sortBy.direction === 'DEFAULT'
    //             ? SORT_OPTIONS.DEFAULT
    //             : formData.sortBy.direction
    //       }
    //     : null
    // };

    return httpClient.post(ApiConstants.REPORT.EXECUTE(true), formData, { params });
  }

  /* ************** Create/Update/Delete Report **************  */
  public async saveReport(payload: any) {
    return httpClient.post(ApiConstants.REPORT.CREATE, payload);
  }

  public async updateReport(payload: any, reportId: string) {
    return httpClient.put(ApiConstants.REPORT.UPDATE(reportId), payload);
  }

  public async deleteReport(reportId: string) {
    return httpClient.delete(ApiConstants.REPORT.UPDATE(reportId));
  }

  /* ************** Misc Report Utils **************  */
  public async sendEmail(requestData: any, appName = APP_NAME.CRM) {
    return httpClient.post(
      ApiConstants.REPORT.SEND_EMAIL(appName === APP_NAME.CRM),
      requestData
    );
  }

  public async exportData(
    requestData: any,
    appName = APP_NAME.CRM3,
    fileName: string
  ) {
    const requestConfig: any = {
      params: {
        fileType: requestData?.fileType === 'XLSX' ? 'XLS' : 'CSV'
      }
    };

    if (appName !== APP_NAME.CRM3) {
      requestConfig['responseType'] = 'blob';
    }

    const fileType = (requestData?.fileType || 'XLSX').toLowerCase();

    return httpClient
      .post(
        ApiConstants.REPORT.EXPORT_DATA(appName === APP_NAME.CRM3),
        requestData,
        requestConfig
      )
      .then((res: any) => {
        if (appName === APP_NAME.CRM3) {
          if (res?.['is_async']) {
            showAlert(
              'Exporting widget data!',
              'Your data export is in progress. You will receive a download link for the same on your email once completed.'
            );
          } else if (res?.download_link) {
            triggerDownload(null, `${fileName}.${fileType}`, res.download_link);
          } else {
            showAlert(
              'Export failed!',
              'Something went wrong while exporting your widget data, please try again.'
            );
          }
        } else {
          triggerDownload(res, `${fileName}.${fileType}`, '');
        }
      })
      .catch((err: Error) => {
        showAlert(
          'Export failed!',
          'Something went wrong while exporting your widget data, please try again.'
        );
      });
  }

  public async exportFullReport(payload: IReportExportPayload) {
    return httpClient.post(ApiConstants.REPORT.EXPORT_FULL_REPORT, payload).then((res: any) => {
      if (res?.['is_async']) {
        showAlert(
          'Exporting widget data!',
          'Your data export is in progress. You will receive a download link for the same on your email once completed.'
        );
      } else if (res?.download_link) {
        triggerDownload(null, `${payload.reportName}.xlsx`, res.download_link);
      } else {
        showAlert(
          'Export failed!',
          'Something went wrong while exporting your widget data, please try again.'
        );
      }
    })
    .catch((err: Error) => {
      showAlert(
        'Export failed!',
        'Something went wrong while exporting your widget data, please try again.'
      );
    });
  }

  /* ************** Standard/Template Report Data API's ************** */
  public async fetchActivityByContact(params: any) {
    params = { ...params, query: `appName=${APP_NAME.CRM}` };
    return httpClient.get(ApiConstants.REPORT.GET_ACTIVITIES_BY_CONTACT, {
      params
    });
  }
  public async fetchTicketByContact(params: any) {
    params = { ...params, query: `appName=${APP_NAME.CRM}` };
    return httpClient.get(ApiConstants.REPORT.GET_TICKETS_BY_CONTACT, {
      params
    });
  }
  public fetchAutomationLogs = (params: any) => {
    params = { ...params, query: 'appName=CRM3' };
    return httpClient.get(ApiConstants.URL.IMPORT_EXPORT.AUTOMATION_LOGS, {
      params
    });
  };

  public exportAutomationLogs = (params: any) => {
    return httpClient.post(
      ApiConstants.URL.IMPORT_EXPORT.EXPORT_AUTOMATION_LOGS,
      {},
      { params }
    );
  };

  public getAIReportByMessageId = (msgId: any) => {
    return httpClient.get(ApiConstants.AI_REPORT.GET_DATA_BY_MSG_ID(msgId));
  }

  public getAIDefaultReport = (param:any, widget:any) => {
    const {isMultiTenantData , isExport}=widget;
    return httpClient.post(ApiConstants.AI_REPORT.GET_DEFAULT_DATA(isMultiTenantData,isExport), param);
  }

  public getAIReportByContent = (param:any, widget:any) => {
    const {isMultiTenantData , isExport}=widget;

    return httpClient.post(ApiConstants.AI_REPORT.GET_DEFAULT_DATA_BY_CONTENT(isMultiTenantData,isExport), param);
  }
  public getPeopleEmployeeReportFields = () => {
    const param = {"configNames":["EMPLOYEE_CONFIG"]}
    return httpClient.post(ApiConstants.PEOPLE_FIELDS.PEOPLE_EMPLOYEE_FIELD(), param);
  }
  public exportAiReportByMessageId = (msgId: any) => {
    return httpClient.get(ApiConstants.AI_REPORT.EXPORT_BY_ID(msgId));
  }
}

export default ReportService;
