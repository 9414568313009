import React, { Component, createRef } from "react";

import {
    DKLabel,
    DKButton,
    DKIcons,
    DKSegmentControl,
    showLoader,
    showAlert,
    removeLoader,
    DKContactIcon,
    DKIcon,
    getDateAsString,
    showToast,
    TOAST_TYPE
} from "deskera-ui-library";
import RouteManager from "../managers/RouteManager";

import { TEMPLATE, TEMPLATE_GROUP, COLUMN_LAYOUT, SORT_OPTIONS, DATE_FORMATS, CHART_TYPE, TEMPLATE_CHART_TYPE, OLD_CHART_TYPES, FILTER_LOGICAL_OPERATORS } from "../constants/Enum";
import TemplateManager from "../managers/TemplateManager";
import { APP_NAME, APP_SCROLL_CONTAINER_ID, PAGE_ROUTES, QUERY_PARAM_KEYS, WEEK_DAYS } from "../constants/Constant";
import { IChartData } from "../models/Common";
import { generateSnapshotFromDOM, getCapitalized, getQueryParam, getRandomNumber, isEmpty, isEmptyObject, scrollToBottom, shiftArrayElement } from "../utilities/Common";
import { connect, ConnectedProps } from "react-redux";
import { IDataTable, IExportColumnConfig, IReport, IReportExportPayload, IWidget, IWidgetSortBy } from "../models/Report";
import ReportService from "../services/reports/Report";
import SaveReport from "./SaveReport";
import Widget from "../components/common/Widget";
import star from '../assets/star.png'
import favourite from '../assets/favourite.png'
import { fetchReportsByPage } from "../store/slices/reportSlice";
import { uploadFileToS3 } from "../services/common/FileUpload";
import { getUserFullName } from "../store/slices/tenantSlice";
import { getDateFromString } from "../utilities/Date";
import { ReportManager } from "../managers/ReportManager";
import { SortableContainer } from 'react-sortable-hoc';

export interface ICreateReportProps extends PropsFromRedux {
    selectedReport: any;
    location: any;
    match: any;
    history: any;
    aiReport?: any;
}
export interface ICreateReportState {
    widgets: IWidget[];
    columnLayout: COLUMN_LAYOUT;
    needGraphList: boolean;
    needDataSourcePicker: boolean;
    didConnectedToDrive: boolean;
    openSavePopup: boolean;
    markAsStarred: boolean;
    currentWidgetIndex: number | undefined;
    unsavedChanges: number;
    isSystemReport: boolean;
}
const SortableWidgetContainer = SortableContainer<any>((props: any) => {
    return <div
        id="canvas"
        className="row mt-xl justify-content-betweens flex-wrap"
        style={{ gap: 15 }}
    >
        {props.widgets.map((widget: IWidget, index: number) => <Widget
            {...props}
            aiReport={props.aiReport}
            widget={widget}
            key={widget.id}
            index={index}
            widgetIndex={index}
            onSortApply={(filterData: any) => props.onSortApply(filterData, index)}
            onSortEnd={(oldIndex: any, newIndex: any) => props.onSortEnd(oldIndex, newIndex)}
            onFilter={(filterData: any, operator?: FILTER_LOGICAL_OPERATORS) => props.onFilter(filterData, operator, index)}
            onFilterDateApplied={(filterDate: any) => props.onFilterDateApplied(filterDate, index)}
            onToggleGlobalLocalReport={(isMultiTenantData: any)=> props.onToggleGlobalLocalReport(isMultiTenantData, index)}
        />)}

    </div>
});

const getRedirectPathFromChartType = (chartType: string) => {
    switch(chartType) {
        case TEMPLATE_CHART_TYPE.PNL:
            return PAGE_ROUTES.PROFIT_LOSS;
        case TEMPLATE_CHART_TYPE.BALANCE_SHEET:
            return PAGE_ROUTES.BALANCE_SHEET;
        case TEMPLATE_CHART_TYPE.TRIAL_BALANCE:
            return PAGE_ROUTES.TRIAL_BALANCE;
        default:
            return "";
    }
}

class CreateReport extends Component<ICreateReportProps, ICreateReportState> {
    selectedWidget: any;
    id: string;
    selectedReport: any;
    constructor(props: ICreateReportProps) {
        super(props);
        this.id = this.props.match.params?.id;
        this.state = this.getInitialState();
        this.selectedWidget = createRef<IChartData>();
    }

    getInitialState() {
        const widgets = this.getReportById();
        return ({
            columnLayout: COLUMN_LAYOUT.TWO,
            widgets,
            needGraphList: false,
            needDataSourcePicker: false,
            didConnectedToDrive: false,
            openSavePopup: false,
            markAsStarred: this.selectedReport?.favourite ?? false,
            currentWidgetIndex: undefined,
            unsavedChanges: 0,
            isSystemReport: false
        });
    }

    componentDidMount() {
        RouteManager.setPresenter(this);
        this.loadTemplate();
        this.checkForCopy();
    }
    componentDidUpdate(prevProps: Readonly<ICreateReportProps>): void {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }
    getAppName = (widget: IWidget) => {
        if (widget.appName) return widget.appName;

        const tableData = this.props.tables[widget.tableName];
        return tableData?.appName || APP_NAME.ERP;
    };
    parseReportWidgets = () => {
        let widgets = [this.getWidget()];

        try {
            const decodeData = JSON.parse((decodeURIComponent(atob(this.selectedReport.data)))) as IWidget;
            widgets = decodeData as any;
            if (!isEmpty(widgets) && !Array.isArray(widgets) && widgets["generatedFrom"] === "books") {
                widgets = [widgets];
            }

            if (!Array.isArray(decodeData)) {
                widgets = [decodeData];
            }

            let redirectPath = "";
            widgets.some((widget) => {
                redirectPath = getRedirectPathFromChartType(widget.type as string);
                return !isEmpty(redirectPath);
            });

            if (redirectPath) {
                RouteManager.navigateToPage(redirectPath);
                return [];
            }

            const availableChartTypes = Object.values(CHART_TYPE);

            // widgets = JSON.parse(atob(this.selectedReport?.data));
            widgets = widgets.map((widget: IWidget) => {
                if (!availableChartTypes.includes(widget.type as CHART_TYPE)) {
                    widget.type = OLD_CHART_TYPES[widget.type as never] || CHART_TYPE.TABLE;
                }
                widget.paginationData = {
                    totalPages: widget.paginationData?.totalPages as number,
                    currentPage: 1,
                    pageSize: widget.paginationData?.pageSize as number
                }
                if (widget.columns && !widget.dataTables) {
                    widget['dataTables'] = [{
                        name: widget.tableName,
                        columns:widget.columns,
                        customFieldColumns: widget.customFieldColumns || [],
                        appName: this.getAppName(widget),
                    }]
                }
                if (!widget['dataTables']) return widget;

                const tableData = widget.dataTables?.[0];

                if (!tableData?.columns) return widget;

                widget.refreshCount = 1;
                widget.columns = tableData.columns;
                widget.tableName = tableData.name;
                return widget;
            })

        } catch (error) {
            console.error('Unable to parse report data!');
        }

        return widgets;
    }

    getReportById() {
        let widgets = [this.getWidget()];

        if (!this.id) return widgets;

        this.selectedReport = this.props.reports?.content?.find((report: any) => report.uid === this.id);
        if (this.selectedReport) {
            widgets = this.parseReportWidgets();
        } else {
            this.fetchReportById();
            return [];
        }

        return widgets;
    }

    async fetchReportById() {
        if (!this.id) return;

        let widgets = [this.getWidget()];

        try {
            this.selectedReport = await ReportService.getInstance().fetchReportById(this.id);
            widgets = this.parseReportWidgets();
        } catch (err) {
            console.error('Error while fetching report data!');
        }

        this.setState({ widgets, markAsStarred: Boolean(this.selectedReport?.favourite), unsavedChanges: 0 });
    }

    loadTemplate() {
        const templateGroup = getQueryParam(
            QUERY_PARAM_KEYS.TEMPLATE_GROUP
        ) as TEMPLATE_GROUP;
        const templateName = getQueryParam(
            QUERY_PARAM_KEYS.TEMPLATE_NAME
        ) as TEMPLATE;

        if (!templateGroup || !templateName) return;

        let templateWidgets: any = TemplateManager.getTemplateData(
            templateGroup,
            templateName
        );

        if (isEmpty(templateWidgets)) return;

        templateWidgets = templateWidgets.map((widget: any) => ({
            ...widget,
            refreshCount: 1,
            appName: widget.appName ?? (templateGroup === TEMPLATE_GROUP.CRM_REPORTS ? APP_NAME.CRM : APP_NAME.ERP),
            isExpanded: widget.isExpanded || (widget.type === CHART_TYPE.TABLE)
        })) as IWidget[];

        templateWidgets = JSON.parse((decodeURIComponent(atob(templateWidgets?.[0]?.data))));
        if(templateWidgets?.length <= 1) templateWidgets[0].isExpanded = true;
        templateWidgets[0].systemReport = true;
        this.setState({
            columnLayout: templateWidgets?.length <= 1 ? this.state.columnLayout : COLUMN_LAYOUT.THREE,
            widgets: templateWidgets,
            isSystemReport: true
        });
    }

    getWidget(): IWidget {
        return {
            id: getRandomNumber(10000).toString(),
            columns: [],
            filters: [],
            logicalOperator: FILTER_LOGICAL_OPERATORS.AND,
            isExpanded: false,
            sortBy: {
                column: '',
                direction: SORT_OPTIONS.DEFAULT,
                table: ''
            },
            tableName: '',
            theme: null,
            type: undefined,
            appName: APP_NAME.ALL,
            refreshCount: 0,
            aiReport: this.props.aiReport
        };
    }
    newWidgetTapped = () => {
        let widgets = this.state.widgets;
        widgets.push(this.getWidget());
        this.setState({ widgets: widgets }, () => scrollToBottom(APP_SCROLL_CONTAINER_ID));
    }
    addGraphToWidget(graphData: any) {
        let widgets = this.state.widgets;
        widgets.splice(this.state.currentWidgetIndex as number, 1, { ...graphData });
        this.setState({ widgets: widgets });
    }
    expandCollapseWidget = (expanded: boolean, index: number) => {
        const widgets = [...this.state.widgets];
        const graphData = { ...widgets[index] };
        graphData.isExpanded = expanded;
        widgets.splice(index, 1, graphData);
        let newWidget = {};
        try {
            const data = JSON.parse(atob(this.selectedReport?.data));
            newWidget = data.find((data: any) => data.id === graphData.id) || {};
            this.selectedReport = JSON.parse(JSON.stringify(this.selectedReport));
            this.selectedReport.data = btoa(JSON.stringify(widgets));
        } catch(err) {
            newWidget = {};
        }

        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }), () => !isEmptyObject(newWidget) && this.onSaveTapped(true));
    }
    onDeleteWidget = (index: number) => {
        const buttons = [
            {
                title: "Cancel",
                className: "bg-gray1 border-m",
            },
            {
                title: "Delete",
                className: "bg-red text-white ml-r",
                onClick: () => {
                    const widgets = [...this.state.widgets];
                    widgets.splice(index, 1);
                    this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }));
                }
            },
        ];
        showAlert(
            "Delete Widget?",
            "Please confirm, if you want to delete this widget.",
            buttons
        );
    }
    onCopyWidget = (widgetName: string, widgetIndex: number) => {
        const widgets = [...this.state.widgets];
        const newWidget = {
            ...widgets[widgetIndex],
            id: getRandomNumber(10000).toString(),
            widgetName: `${widgetName} (copy)`
        };
        widgets.splice(widgetIndex + 1, 0, newWidget);
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }));
    }
    onReportCopy = () => {
        this.id = '';
        this.selectedReport = { ...this.selectedReport as any, uid: null };
        ReportManager.geInstance().setSelectedReport(this.selectedReport);
        this.checkForCopy();
    }
    getWidgetName = (widget: IWidget) => {
        if (widget.widgetName) return widget.widgetName;

        let widgetName = widget.tableName || 'Widget name';
        widgetName = widgetName.replaceAll('crm3', '');
        widgetName = widgetName.replaceAll('_', ' ');
        widgetName = widgetName
          .split(' ')
          .map((word) => getCapitalized(word))
          .join(' ');
        return widgetName;
    }
    onReportExport = () => {
        try {
            const widgetsToExport = this.state.widgets.filter(widget => widget.dataTables && widget.dataTables.length > 0);

            if (isEmpty(widgetsToExport)) return;

            let widgetData = widgetsToExport.map(widget => {
                const exportColumnConfig: IExportColumnConfig[] = [];
                let columnKeyToDataMap: {[key: string] : any} = {};

                const savedColumnSettings: {[key: string] : any} = {};
                const columnSettingsList = widget?.columnConfig || widget?.settings?.columns || [];
                columnSettingsList.forEach((column) => {
                    savedColumnSettings[column.key] = column;
                });

                const dataTables: IDataTable[] = [];

                widget.dataTables?.forEach((dataTable) => {
                    const tableData = this.props.tables[dataTable.name];
                    const allColumns = tableData?.fields || [];
                    columnKeyToDataMap = allColumns.reduce((acc: any, col: any) => {
                        acc[col.name] = col;
                        return acc;
                    }, columnKeyToDataMap);

                    [
                        ...(dataTable.columns || []),
                        ...(dataTable.customFieldColumns || [])
                    ].forEach((column) => {
                        exportColumnConfig.push({
                            display: savedColumnSettings[column]?.name || columnKeyToDataMap[column]?.display || column,
                            key: `${dataTable.name}__${column}`,
                            index: exportColumnConfig.length
                        });
                    });

                    dataTables.push({
                        name: dataTable.name,
                        columns: dataTable.columns,
                        customFieldColumns: dataTable.customFieldColumns || [],
                        appName: dataTable.appName || tableData?.appName || APP_NAME.ERP,
                    });
                });

                return {
                    widgetName: this.getWidgetName(widget),
                    reportMetadata: {
                        chartType: CHART_TYPE.TABLE,
                        dataTables: dataTables,
                        filters: widget.filters || [],
                        additionalFilter: widget.additionalFilter || [],
                        customFieldFilters: widget.customFieldFilters || [],
                        logicalOperator: widget.logicalOperator || FILTER_LOGICAL_OPERATORS.AND,
                        sortBy: widget.sortBy?.column ? {
                            column: widget.sortBy?.column,
                            direction: widget.sortBy?.direction === "DEFAULT" ? SORT_OPTIONS.DEFAULT : widget.sortBy?.direction,
                            table: widget.sortBy?.table || widget.dataTables?.[0]?.name || widget.tableName,
                            dataType: columnKeyToDataMap[widget.sortBy?.column]?.dataType || "STRING",
                        } : null,
                    },
                    exportColumnConfig: exportColumnConfig,
                    fileType: 'XLS'
                };
            });

            const payload: IReportExportPayload = {
                reportName: this.selectedReport?.reportName || `Report_${new Date().getTime()}`,
                widgets: widgetData
            };
            ReportService.getInstance().exportFullReport(payload);
        } catch(err) {
            console.log(err);
        }
    }
    onRouteChanged = () => {
        if (this.id === this.props.match.params?.id) return;

        this.id = this.props.match.params?.id;
        this.selectedReport = null;
        const newState = this.getInitialState();
        this.setState(newState);
    }
    onClickReportRefresh = () => {
        try {
            const newWidgets = (this.state.widgets || []).map(widget => ({
                ...widget,
                refreshCount: (widget.refreshCount ?? 0) + 1
            }));
            this.setState({ widgets: newWidgets });
        } catch(err) {}
    }
    checkForCopy = () => {
        const selectedReport = ReportManager.geInstance().getSelectedReport();
        if (selectedReport != null) {
            this.id = '';
            this.selectedReport = { ...selectedReport as any, uid: null };
            const widgets = this.parseReportWidgets();
            this.setState({ widgets, openSavePopup: true });
        }
    }
    onSortEnd = ({ oldIndex, newIndex }: any) => {
        let { widgets } = this.state;
        this.setState({ widgets: shiftArrayElement(widgets, oldIndex, newIndex) });
    };
    renderReport() {
        return (
            <div className="column p-l parent-width parent-height"
            >
                {this.state.isSystemReport ? (
                    <DKLabel text={"Template"} className="fw-m fs-l" />
                ) : this.getHeader()}
                <SortableWidgetContainer
                    lockToContainerEdges={true}
                    widgets={this.state.widgets}
                    columnLayout={this.state.columnLayout}
                    toggleWidgetExpandedState={this.expandCollapseWidget}
                    onDeleteWidget={this.onDeleteWidget}
                    onCopyWidget={this.onCopyWidget}
                    onDataSourceSave={this.onDataSourceSave}
                    onFilter={this.onFilter}
                    onSortApply={this.onSortApplied}
                    onSortEnd={this.onSortEnd}
                    onFilterDateApplied={this.onFilterDateApplied}
                    onToggleGlobalLocalReport={this.onToggleGlobalLocalReport}
                    axis="xy"
                    useDragHandle={true}
                    isSystemReport={this.state.isSystemReport}
                    aiReport={this.props.aiReport}
                />
            </div>
        );
    }
    getReportBasicDetail() {
        try {
            const ownerData = this.props.userInfo;
            const dateStr = this.selectedReport?.updatedDt || this.selectedReport?.createdDt;
            const updatedAtDate = dateStr ? getDateFromString(dateStr, DATE_FORMATS["DD-MMM-YYYY"]) : new Date();
            const day = WEEK_DAYS[updatedAtDate.getDay()];
            return (
                <div className="row width-auto mt-r">
                    <DKContactIcon
                        title={ownerData?.firstName +' '+ ownerData.lastName}
                        className="bg-chip-blue border-blue text-blue flex-shrink-0"
                    />
                    <DKLabel text={ownerData ? getUserFullName(ownerData) : ''} className="ml-s" />
                    <DKLabel text={`${day}, ${getDateAsString(updatedAtDate, "dd MMM yyyy")}`} className="ml-r text-gray" />
                    {this.state.unsavedChanges > 0 ? <DKLabel className="border-orange border-radius-m bg-chip-orange fw-m p-h-s ml-r" text={`${this.state.unsavedChanges} unsaved changes`} /> : null}
                </div>
            );
        } catch (err) {
            return null;
        }
    }
    getSavePopupData = () => {
        const data = this.selectedReport;
        if (ReportManager.geInstance().getSelectedReport() !== null) {
            return { ...data, reportName: `Copy of ${data.reportName}` };
        }
        return data;
    }
    getHeader() {
        const { markAsStarred } = this.state;
        return (
            <div className="row row-responsive justify-content-between mobile-flex-gap-m">
                <div className="column mobile-max-width-parent" style={{ maxWidth: "50%" }}>
                    <div className="row dk-hover-container">
                        <DKButton
                            icon={DKIcons.ic_arrow_left}
                            className="mr-ss mt-1"
                            onClick={() => this.props.history?.goBack ? this.props.history.goBack() : RouteManager.navigateToPage(PAGE_ROUTES.REPORT_DASHBOARD)}
                            style={{
                                paddingLeft: 0
                            }}
                        />
                        <DKLabel
                            text={this.selectedReport?.reportName || (this.props.aiReport ? "New ai report":"New report")}
                            className="fw-m fs-xl text-ellipsis"
                            style={{
                                WebkitLineClamp: 3
                            }}
                        />
                        {isEmpty(this.id) ? null : <DKIcon
                            className="ml-m cursor-hand"
                            src={(markAsStarred) ? favourite : star}
                            onClick={() => this.onMarkStar(this.selectedReport)}
                        />}
                        {isEmpty(this.id) ? null : <DKIcon
                            src={DKIcons.ic_edit}
                            className="dk-hover-action-button cursor-hand ml-m ic-s-2"
                            onClick={() => this.showHideSavePopup()}
                        />}
                    </div>
                    {this.getReportBasicDetail()}
                </div>
                {!this.state.isSystemReport && <div className="row row-responsive width-auto mobile-parent-width mobile-flex-gap-s">
                    <DKButton icon={DKIcons.ic_repeat} className="mr-s mobile-m-0" onClick={() => this.onClickReportRefresh()} disabled={!this.id} />
                    <DKSegmentControl
                        width={120}
                        segments={["|", "||", "|||"]}
                        backgroundColor="bg-gray2 display-only-web"
                        selectedColor=""
                        barColor="bg-white"
                        selectedIndex={parseInt(this.state.columnLayout) - 1}
                        onSelect={(index: number) => {
                            this.setState({
                                columnLayout: "" + (index + 1) as COLUMN_LAYOUT,
                            });
                        }}
                    />
                    {!isEmptyObject(this.id) && <DKButton
                        title="Copy"
                        icon={DKIcons.ic_copy}
                        className="ml-r mobile-m-0 bg-gray2 border-m"
                        onClick={this.onReportCopy}
                    />}
                    {!this.props.aiReport && <DKButton
                        title="Export"
                        icon={DKIcons.ic_export}
                        /* Disable export if no widgets, or no widgets with data source present */
                        disabled={!this.state.widgets?.length || !(this.state.widgets.some(widget => !isEmpty(widget.tableName)))}
                        className="ml-r mobile-m-0 bg-gray2 border-m"
                        onClick={this.onReportExport}
                    />}
                    <DKButton
                        icon={DKIcons.white.ic_plus}
                        className="bg-button text-white ml-r mobile-m-0"
                        title="Add Widget"
                        onClick={this.newWidgetTapped}
                    />
                    <DKButton
                        title="Save"
                        className="bg-button text-white ml-r mobile-m-0"
                        onClick={() => this.onSaveTapped()}
                    />
                </div>}
                {this.state.openSavePopup && <SaveReport
                    data={this.getSavePopupData()}
                    onSave={(data: any) => {
                        this.onReportSave(data);
                        this.showHideSavePopup();
                    }}
                    onCancel={() => { this.id = this.props.match.params?.id; this.setState({ unsavedChanges: 0 }); this.showHideSavePopup()}} />}
            </div>
        );
    }

    onDataSourceSave = (widget: IWidget, preventRefresh?: boolean, preventAutoSave?: boolean, needReportSave = true) => {
        const { widgets } = this.state;
        const index = widgets?.findIndex((w: any) => widget.id === w.id);
        if (!preventRefresh) {
            widget.refreshCount = (widget.refreshCount ?? 0) + 1;
        }
        if (index === -1) {
            widgets.push(widget)
        } else {
            widgets[index] = widget;
        }
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + (needReportSave ? 1 : 0) }), () => preventAutoSave ? {} : this.onSaveTapped(true));
    }
    onFilter = (filters: any, operator: FILTER_LOGICAL_OPERATORS, index: number) => {
        const widgets = [...this.state.widgets];
        const graphData = { ...widgets[index] };
        graphData.filters = filters.filter((filter:any) => !filter.customField && !filter.additionFilter);
        graphData.additionalFilter = filters.filter((filter:any) => filter.additionFilter);
        graphData.customFieldFilters = filters.filter((filter: any) => filter.customField);
        graphData.logicalOperator = operator || FILTER_LOGICAL_OPERATORS.AND;
        if (graphData.paginationData) {
            graphData.paginationData.currentPage = 1;
        }
        let newWidget = {};
        try {
            let data = JSON.parse((decodeURIComponent(atob(this.selectedReport.data))));
            if (!Array.isArray(data)) {
                data = [data];
            }
            newWidget = data.find((data: any) => data.id === graphData.id) || {};
        } catch(err) {
            newWidget = {};
        }
        graphData.refreshCount = (graphData.refreshCount ?? 0) + 1;
        widgets.splice(index, 1, graphData);
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }), () => !isEmptyObject(newWidget) && this.onSaveTapped(true));
    }
    onSortApplied = (sortData: IWidgetSortBy, index: number) => {
        const widgets = [...this.state.widgets];
        const graphData = { ...widgets[index] };
        graphData.sortBy = sortData;
        if (graphData.paginationData) {
            graphData.paginationData.currentPage = 1;
        }
        let newWidget = {};
        try {
            let data = JSON.parse((decodeURIComponent(atob(this.selectedReport.data))));
            newWidget = data.find((data: any) => data.id === graphData.id) || {};
        } catch(err) {
            newWidget = {};
        }

        graphData.refreshCount = (graphData.refreshCount ?? 0) + 1;
        widgets.splice(index, 1, graphData);
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }), () => !isEmptyObject(newWidget) && this.onSaveTapped(true));
    }
    onFilterDateApplied = (date: any, index: number) => {
        const widgets = [...this.state.widgets];
        const graphData = { ...widgets[index] };
        if (graphData.paginationData) {
            graphData.paginationData.currentPage = 1;
        }
        let newWidget = {};
        try {
            let data = JSON.parse((decodeURIComponent(atob(this.selectedReport.data))));
            newWidget = data.find((data: any) => data.id === graphData.id) || {};
        } catch(err) {
            newWidget = {};
        }

        graphData.refreshCount = (graphData.refreshCount ?? 0) + 1;
        widgets.splice(index, 1, graphData);
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }), () => !isEmptyObject(newWidget) && this.onSaveTapped(true));
        
    }
    onToggleGlobalLocalReport = (data: any, index: number) => {
        const widgets = [...this.state.widgets];
        const graphData = { ...widgets[index] };
        if (graphData.paginationData) {
            graphData.paginationData.currentPage = 1;
        }
        let newWidget = {};
        try {
            let data = JSON.parse((decodeURIComponent(atob(this.selectedReport.data))));
            newWidget = data.find((data: any) => data.id === graphData.id) || {};
        } catch(err) {
            newWidget = {};
        }

        graphData.refreshCount = (graphData.refreshCount ?? 0) + 1;
        widgets.splice(index, 1, graphData);
        this.setState((prevState) => ({ widgets: widgets, unsavedChanges: prevState.unsavedChanges + 1 }), () => !isEmptyObject(newWidget) && this.onSaveTapped(true));
        
    }

    render() {
        return (
            <div className="full-screen-width row align-items-start bg-white border-radius-s flex-1">
                {this.renderReport()}
            </div>
        );
    }

    onSaveTapped = (autoSave = false) => {
        if (autoSave && isEmpty(this.id)) return;

        if (isEmpty(this.state.widgets)) {
            showAlert("Can't Save?", "Please add a widget to proceed.");
            return;
        }

        if (autoSave) {
            this.saveReport(this.selectedReport, true);
        } else if (isEmpty(this.id)) {
            this.showHideSavePopup();
        } else {
            this.onReportSave(this.selectedReport);
        }
    }
    onReportSave = (data: any) => {
        showLoader('Saving report...');
        try {
            generateSnapshotFromDOM('canvas').then((canvasObj: HTMLCanvasElement) => {
                canvasObj.toBlob((blob: any) => {
                    uploadFileToS3(blob).then(({ relativePath }: any) => {
                        this.saveReport({ ...data, thumbnail: relativePath });
                    })
                }, 'image/png') as any;
            });
        } catch (error) {
            console.error(`Error uploading snapshot to server: ${error}`);
            this.saveReport({ ...data });
        }
    }
    saveReport = (data: any, isAutoSave?: boolean) => {
        let payload: IReport;
        const tableName = this.state.widgets[0]?.tableName;
        const appName = this.props.tables?.[tableName]?.[0]?.appName || this.state.widgets[0]?.appName;
        if (isEmpty(this.id)) {
            payload = {
                appName: appName || 'ERP',
                data: btoa(encodeURIComponent(JSON.stringify(this.state.widgets))),
                favourite: !!data.favourite,
                reportDesc: data.description || data.reportDesc,
                reportName: data.name || data.reportName,
                thumbnail: data.thumbnail,
                systemReport: false,
            };
        } else {
            payload = {
                ...this.selectedReport,
                favourite: !!data.favourite,
                reportDesc: data.description || data.reportDesc,
                reportName: data.name || data.reportName,
                thumbnail: data.thumbnail,
                data: btoa(encodeURIComponent(JSON.stringify(this.state.widgets))),
                appName: data.appName || 'ERP'
            };
        }

        /* Converting to relative url, as during autoSave no new thumbnail is generated */
        if (isAutoSave && `${data.thumbnail || ""}`.includes("https://")) {
            try {
                let relativeThumbnailUrl = new URL(data.thumbnail).pathname;
                relativeThumbnailUrl = relativeThumbnailUrl.substring(1);
                payload.thumbnail = relativeThumbnailUrl;
            } catch(err) {}
        }

        if(this.props.aiReport) {
            payload['peopleGlobalAccess'] = true;
        }
        const _reportService = ReportService.getInstance();

        if (!isEmpty(this.id)) {
            _reportService.updateReport(payload, this.id).then(res => {
                if (!this.state.markAsStarred && !isAutoSave) {
                    if(this.props.aiReport) {
                        RouteManager.navigateToPage(PAGE_ROUTES.PEOPLE_AI_REPORT);
                    } else {
                        RouteManager.navigateToPage(PAGE_ROUTES.REPORTS);
                    }
                    ReportManager.geInstance().setSelectedReport(null);
                }
                else {
                    this.setState({ unsavedChanges: 0 });
                }
            }, err => {
                console.error(err);

            }).finally(() => {
                removeLoader();
                showToast("Report saved!", TOAST_TYPE.SUCCESS);
            })
        } else {
            _reportService.saveReport(payload).then((res: any) => {
                if (isAutoSave) {
                    this.setState({ unsavedChanges: 0 });
                } else {
                    if (isEmptyObject(ReportManager.geInstance().getSelectedReport())) {
                        if(this.props.aiReport) {
                            RouteManager.navigateToPage(PAGE_ROUTES.PEOPLE_AI_REPORT);
                        } else {
                            RouteManager.navigateToPage(PAGE_ROUTES.REPORTS);
                        }
                    } else {
                        this.selectedReport = res;
                        if (this.props.match.params?.id !== this.selectedReport.uid) {
                            if (this.props.history?.replace) {
                                this.props.history.replace(`${PAGE_ROUTES.CREATE_REPORT}/${this.selectedReport.uid}`);
                            } else {
                                RouteManager.navigateToPage(`${PAGE_ROUTES.CREATE_REPORT}/${this.selectedReport.uid}`);
                            }
                        }
                        this.id = res.uid;
                        this.setState({ unsavedChanges: 0 });
                    }
                    ReportManager.geInstance().setSelectedReport(null);
                }
            }, err => {
                console.error(err);

            }).finally(() => {
                removeLoader();
                showToast("Report saved!", TOAST_TYPE.SUCCESS);
            })
        }
    }
    onMarkStar = (report: any) => {
        const _reportService = ReportService.getInstance();
        this.setState((prev) => ({ markAsStarred: !report.favourite }));
        _reportService.updateReport({ ...report, favourite: !report.favourite }, this.id).then(res => {
            this.props.fetchReportsByPage({}).then(() => {
                this.getReportById();
            })
        }, err => {
            console.error(err);

        }).finally(() => {
            removeLoader();
        })
    }
    showHideSavePopup = () => {
        this.setState((prevState) => ({ openSavePopup: !prevState.openSavePopup }));
    }
}
const mapStateToProps = (state: any, ownProps: any) => ({
    reports: state.reports.reports,
    tables: state.table.reportsTables,
    userInfo: state.tenant.userInfo,
    state: state
});
const mapDispatchToProps = {
    fetchReportsByPage
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CreateReport);
