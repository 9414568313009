import { CURRENCY_SYMBOLS } from '../constants/Currencies';
import { store } from '../store/store';
import PdfUtility from './PDFUtility';

/**
 *
 * @param {*} valueToCheck - object to check (string, number, array, date, object)
 * @returns boolean - true if object is empty else false
 */
export function isEmpty(valueToCheck: any) {
  if (valueToCheck === null || valueToCheck === undefined) return true;

  if (Array.isArray(valueToCheck)) return !valueToCheck.length;

  if (typeof valueToCheck === 'string') return !valueToCheck.trim().length;

  if (typeof valueToCheck === 'number')
    return !valueToCheck && valueToCheck !== 0;

  if (valueToCheck instanceof Date)
    return valueToCheck.getTime && isNaN(valueToCheck.getTime());

  if (typeof valueToCheck === 'object')
    return Object.keys(valueToCheck).length === 0;
  return !valueToCheck;
}

export const deepCloneObject = (objectToClone: any) => {
  if (isEmpty(objectToClone)) return objectToClone;

  return JSON.parse(JSON.stringify(objectToClone));
};

export function getUniqueAlphaNumericString(length = 16) {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function paramsSerializer(key: string, params: any) {
  let paramString = '';
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      paramString += `${key}=${params[key]}&`;
    }
  }
  return key + '=' + paramString.substring(0, paramString.lastIndexOf('&'));
}

export function isMacintosh() {
  let deviceDetect = navigator.platform;
  let appleDevicesArr = [
    'MacIntel',
    'MacPPC',
    'Mac68K',
    'Macintosh',
    'iPhone',
    'iPod',
    'iPad',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad Simulator',
    'Pike v7.6 release 92',
    'Pike v7.8 release 517'
  ];

  if (appleDevicesArr.includes(deviceDetect)) {
    return true;
  }

  return false;
}
export function getRandomNumber(number = 1000) {
  return Math.floor(Math.random() * number);
}

export function getQueryParam(param: string) {
  const location = window.location;
  if (location?.search && param) {
    const query = new URLSearchParams(location.search);
    return query.get(param);
  }

  return null;
}

export const generateSnapshotFromDOM = async (elementId: string) => {
  const targetElement = document.getElementById(elementId);
  const canvasObj = await PdfUtility.generateCanvas(
    targetElement as HTMLElement
  );
  return canvasObj;
};

export const debounce = (func: any, wait: any) => {
  let timeout: any;
  return (...args: any) => {
    const context = this;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
export const isEmptyObject = (objectToCheck: any) => {
  if (
    objectToCheck === null ||
    objectToCheck === undefined ||
    objectToCheck.length === 0
  )
    return true;
  if (Array.isArray(objectToCheck)) return !objectToCheck.length;
  if (typeof objectToCheck === 'string') return !objectToCheck.trim().length;
  return Object.keys(objectToCheck).length === 0;
};

export const getCapitalized = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertInTitleCase = (str: string) => {
  if (
    str === undefined ||
    str === null ||
    typeof str === undefined ||
    str === ''
  )
    return '';
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const toCurrencyFormat = (value: any, needCRMCurrency?: boolean) => {
  let currency: unknown;
  if (needCRMCurrency) {
    currency = store.getState().tenant.crmTenantSetting?.currency;
  }

  if (!currency) {
    currency = store.getState().tenant.tenantInfo?.currency;
  }

  if (!value) {
    value = `0.00`;
  }
  
  const currencySymbol = CURRENCY_SYMBOLS[currency as never] || '';
  return currencySymbol ? `${currencySymbol} ${value}` : value;
};
export const scrollToBottom = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({ behavior: 'smooth', top: element.scrollHeight });
  }
};
export function shiftArrayElement(
  arr: any[],
  fromIndex: number,
  toIndex: number
) {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function setPersistentValue(key: any, value: any) {
  localStorage.setItem(key, value);
}

export function getPersistentValue(key: any) {
  return localStorage.getItem(key);
}

export const isString = (value: any) => {
  return (
    typeof value === 'string' ||
    value instanceof String ||
    Object.prototype.toString.call(value) === '[object String]'
  );
};
export function isUndefined(value: any) {
  return typeof value === 'undefined';
}
export function encodeJSON(json: any) {
  let temp = encodeURI(JSON.stringify(json));
  return btoa(temp);
}
export function decodeJSON(base64String: any) {
  return JSON.parse(decodeURI(atob(base64String)));
}
export function isValidDate(string: any) {
  const date = new Date(string);
  return Boolean(date.getTime());
}
export function getRandomHexString(length = 24) {
  const chars = '0123456789abcdef';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function formatNumberToDecimal(num: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(num);
}

export function isValidEmail(email: string) {
  if (email.trim() === '') {
    return false;
  }
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const decodeBase64Uri = (encodedData: any) => {
  try {
    return decodeURIComponent(
      Buffer.from(encodedData, 'base64').toString('ascii')
    );
  } catch (error) {
    return encodedData;
  }
};

export const encodeBase64Uri = (decodedData: any) => {
  try {
    return Buffer.from(encodeURIComponent(decodedData), 'ascii').toString(
      'base64'
    );
  } catch (error) {
    return decodedData;
  }
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const deepClone = (obj: any) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    return obj;
  }
};

export const modifyPeopleColumn = (columns:any) => {
  let columnConfigs:any = [];
  columns.forEach((section:any)=>{
    section.sections.forEach((sec:any)=>{
      let columnObj:any = {};
      sec.fields.forEach((col:any)=>{
        columnObj = {
          "country": null,
          "currency": null,
          "customField": null,
          "data_type": col.type === 'dropdown' ? 'select' : col.type,
          "display": col.name || col.title,
          "name": (col.id || col.columnCode),
          "possible_values": col.options?.map((op:any)=>op.name) || [],
          "showInFilters": null
        }
        if(columnObj['display']) {
          columnConfigs.push(columnObj);
        }
      })
    })
    
  })
  return columnConfigs;
}